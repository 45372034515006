import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {getTrackingSDK} from "../../clientActivity/helpers/clientActivitySDK";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {toastActions} from "../../toaster/redux";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import groupTherapySDK from "../helpers/groupTherapySdk";

const log = createLogger("deleteGroupRegistrationSaga", SentryTags.GroupTherapy);

/**
 * This saga will handle group registration deletion.
 * Steps:
 * 1. Delete group registration by id
 * 2. Delete related sensors from given `sensorIds`
 */
function* deleteGroupRegistration(action: ActionType<typeof groupTherapyActions.deleteGroupRegistration.request>) {
	const {groupId, registrationDetails} = action.payload;
	const token: string = yield call(getSessionId);

	try {
		// 1. Delete group registration session
		yield apiCallSaga(groupTherapySDK.deleteRegistration, token, groupId, registrationDetails.id);

		for (const sensorId of registrationDetails.sensorIds) {
			// 2. Delete each related sensors
			yield apiCallSaga(getTrackingSDK().deleteSensorData, sensorId);
		}

		// Refetch registration list
		yield put(groupTherapyActions.deleteGroupRegistration.success());
		yield put(groupTherapyActions.fetchGroupRegistrationList.request({id: groupId}));
		yield put(groupTherapyActions.clearFetchedRegistrationDetails());

		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GRAPHS.delete_event.success.toast),
				type: "info",
			}),
		);
	} catch (err) {
		log.captureException(err, {message: "Unable to delete group registration."});
		yield put(groupTherapyActions.deleteGroupRegistration.failure(err));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GRAPHS.delete_event.fail.toast),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.deleteGroupRegistration.request, deleteGroupRegistration);
}
