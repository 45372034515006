import {GroupTherapy} from "@sense-os/goalie-js";

const groupTherapySDK = new GroupTherapy();

// Group management
groupTherapySDK.createGroupTherapy = groupTherapySDK.createGroupTherapy.bind(groupTherapySDK);
groupTherapySDK.deleteGroupTherapy = groupTherapySDK.deleteGroupTherapy.bind(groupTherapySDK);
groupTherapySDK.updateGroupTherapyName = groupTherapySDK.updateGroupTherapyName.bind(groupTherapySDK);
groupTherapySDK.updateGroupTherapyMembers = groupTherapySDK.updateGroupTherapyMembers.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyDetail = groupTherapySDK.getGroupTherapyDetail.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyList = groupTherapySDK.getGroupTherapyList.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyMemberList = groupTherapySDK.getGroupTherapyMemberList.bind(groupTherapySDK);

// Group registration
groupTherapySDK.getGroupRegistrationList = groupTherapySDK.getGroupRegistrationList.bind(groupTherapySDK);
groupTherapySDK.createRegistration = groupTherapySDK.createRegistration.bind(groupTherapySDK);
groupTherapySDK.updateRegistration = groupTherapySDK.updateRegistration.bind(groupTherapySDK);
groupTherapySDK.deleteRegistration = groupTherapySDK.deleteRegistration.bind(groupTherapySDK);
groupTherapySDK.getRegistrationDetail = groupTherapySDK.getRegistrationDetail.bind(groupTherapySDK);

// bulk sensors
groupTherapySDK.createBulkSensors = groupTherapySDK.createBulkSensors.bind(groupTherapySDK);
groupTherapySDK.updateBulkSensors = groupTherapySDK.updateBulkSensors.bind(groupTherapySDK);

export default groupTherapySDK;
